<script setup>
import ButtonLink from '@/Components/Button/Link.vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';

const props = defineProps({
    title: String,
    description: String,
    link: String,
    buttonText: String,
    dontHide: Boolean,

});
let interestedCallToAction = ref(true);


</script>
<template>
    <div
        v-if="interestedCallToAction"
        :class="dontHide ? '' : 'hidden xl:flex'"
        class="fixed bottom-0 right-0 z-50 max-w-md flex-col gap-2 rounded-tl-[3rem] bg-gradient-to-r from-pink to-orange p-7 shadow-xl sm:bottom-10 sm:right-10 sm:gap-5"
    >
        <div class="relative">
            <h2 class="max-w-70 text-2xl font-black uppercase sm:text-3xl">
                {{ title }}
            </h2>
            <XMarkIcon
                class="absolute right-0 top-0 h-8 w-8 text-white"
                @click="interestedCallToAction = false"
            />
        </div>
        <span class="text-lg">
            {{ description }}
        </span>

        <div v-if="link">
            <ButtonLink
                :href="link"
                blue
            >
                {{ buttonText }}
            </ButtonLink>
        </div>
    </div>
</template>
